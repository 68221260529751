html,
body,
#__next {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiAppBar-root {
  box-shadow: none !important;
}
/* 
peach: #FFC371
pink:  #FF5F6D
light blue: #354969
medium blue: #24354F
dark blue: #1C2A3F 

gray: '#B7BDC7'
darkpeach: '#FF990A'
luva dark blue: #194072
luva blue: #2E62A6
*/

/* Color Theme Swatches in Hex */
.colors-1-hex {
  color: #1c2a3f;
}
.colors-2-hex {
  color: #2e62a6;
}
.colors-3-hex {
  color: #f29422;
}
.colors-4-hex {
  color: #d97014;
}
.colors-5-hex {
  color: #732c02;
}

/* Color Theme Swatches in RGBA */
.colors-1-rgba {
  color: #194072;
}
.colors-2-rgba {
  color: rgba(46, 98, 165, 1);
}
.colors-3-rgba {
  color: rgba(242, 148, 33, 1);
}
.colors-4-rgba {
  color: rgba(216, 111, 19, 1);
}
.colors-5-rgba {
  color: rgba(114, 43, 2, 1);
}

/* Color Theme Swatches in HSLA */
.colors-1-hsla {
  color: hsla(213, 63, 27, 1);
}
.colors-2-hsla {
  color: hsla(213, 56, 41, 1);
}
.colors-3-hsla {
  color: hsla(33, 89, 54, 1);
}
.colors-4-hsla {
  color: hsla(28, 83, 46, 1);
}
.colors-5-hsla {
  color: hsla(22, 96, 22, 1);
}

.easyRead p {
  line-height: 2 !important;
}

.whiteInput fieldset {
  border-color: white;
}

.nav {
  width: 64px;
  min-width: 64px;
  color: white;
  flex-shrink: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  transition: all ease-in-out 250ms;
  background: #ff5f6d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #ffc371,
    #ff5f6d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #ffc371, #ff5f6d);
  z-index: 100;
}

.layout {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
}

.header {
  height: 60px;
  min-height: 60px;
  background-color: #24354f;
  width: 100%;
  z-index: 10;
  position: sticky;
  top: 0;
}

.overlay {
  background-color: #35496950;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0px;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuButton {
  position: initial;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}

.menuButton button {
  margin: 0;
}

body .nav.open .menuButton {
  display: none !important;
}

@media screen and (max-width: 650px) {
  .nav {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    z-index: 100;
    left: -100%;
    background: none;
  }

  .nav.open {
    left: 0;
    background-color: #1c2a3f85;
  }

  .innerMenu {
    background: #ff5f6d; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to top,
      #ffc371,
      #ff5f6d
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #ffc371, #ff5f6d);
  }

  .desktopHomeLink {
    display: none;
  }

  .mobileHomeLink {
    display: block !important;
  }

  .pageWrapper {
    padding: 8px !important;
  }
}

.mobileHomeLink {
  display: none;
}

.menuHeader {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  padding-left: 20px;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
}

.innerMenu {
  width: 240px;
  height: 100%;
}

html,
body,
main,
.center {
  background-color: #1c2a3f;
  color: white;
}

input {
  color: white !important;
}

.nav .MuiListItemText-primary {
  font-size: 1.25rem !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #f0f0f0;
}

.MuiInputLabel-outlined {
  color: #f0f0f0 !important;
}

.MuiOutlinedInput-root:hover fieldset {
  border-color: white !important;
}

.MuiButton-outlined.Mui-disabled {
  color: #f0f0f080 !important;
  border-color: #f0f0f080;
}

.MuiChip-filledDefault {
  border: 1px solid #24354f;
}

.MuiChip-filledGray:hover {
  background-color: #b7bdc799;
}

.MuiChip-filledGray.Mui-focusVisible {
  background-color: #b7bdc7;
}
.MuiChip-filledGray.Mui-focusVisible:hover {
  background-color: #b7bdc799;
}

.gradient-text {
  /* color: rgb(25,64,115); */
  background: linear-gradient(180deg, #ff5f6d 0%, #ffc371 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pageWrapper {
  padding: 24px;
}

.featuredPost p {
  margin: 12px 0;
}

.MuiChip-label {
  display: flex;
  align-items: center;
}

.MuiChip-label input {
  padding-left: 4px;
  padding-bottom: 2px;
}


.featuredPost {
  max-width: 800px;
  margin: auto;
}

.MuiCheckbox-root svg {
  fill: white !important;
}

.multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}