.footerWrapper {
  background-color: #24354f;
  padding: 20px;
  color: white;
}

.footer {
  width: 100%;
  margin: auto;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer > div {
  padding: 10px 0;
}

.sectionHeader {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.footerLink {
  margin: 4px 0px;
}
